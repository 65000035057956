'use client'

import Image, { StaticImageData } from 'next/image'

import Chris from '@/public/images/testimonials/chris-v2.jpg'
import Spike from '@/public/images/testimonials/spike-bw.jpg'
import Danny from '@/public/images/testimonials/danny-v3.jpg'
import Eg from '@/public/images/testimonials/eg-v2.jpg'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import React, { useCallback } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

const TestimonialCard = (name: string, image: StaticImageData, quote: string, credits: string) => {
    return (
        <>
            {/* desktop version */}
            <div className="hidden h-full flex-col justify-between rounded-xl bg-gray-800 p-6 font-poppins text-gray-200 lg:flex" data-aos="fade-up">
                <div className="flex flex-col">
                    <div className="relative mx-auto">
                        <Image className="rounded-full" src={image} width={256} height={256} alt={name} placeholder="blur" />
                    </div>
                    <blockquote className="mt-6 text-center text-base font-normal text-gray-200">"{quote}"</blockquote>
                </div>
                <div className="mt-6 h-20 font-normal">
                    <p className="gradient-text text-center font-opensans text-xl font-semibold uppercase">{name}</p>
                    <p className="text-md text-center font-light text-gray-200">{credits}</p>
                </div>
            </div>
            {/* mobile version */}
            <div className="flex h-[21.3rem] w-full flex-col rounded-xl bg-gray-800 p-1 sm:p-4 font-poppins text-gray-200 sm:h-52 sm:flex-row md:p-6 lg:hidden" data-aos="fade-up">
                <div className="relative mx-auto my-auto flex min-w-40 sm:pl-2 md:p-0">
                    <Image className="h-full w-full rounded-full object-contain" height={128} width={128} src={image} alt={name} placeholder="blur" />
                </div>
                <div className="mx-auto my-auto px-4 sm:p-4 font-normal">
                    <blockquote className="text-center text-sm font-normal text-gray-200 md:text-base lg:text-lg">"{quote}"</blockquote>
                    <p className="gradient-text mt-1 text-center font-opensans text-sm font-semibold uppercase sm:text-base md:text-xl">{name}</p>
                    <p className="mt-1 text-center text-xs font-light text-gray-200  sm:text-sm md:text-base">{credits}</p>
                </div>
            </div>
        </>
    )
}

export default function Testimonials() {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' }, [Autoplay()])
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    const testimonials = [
        { name: 'Mark "Spike" Stent', image: Spike, quote: 'This is a game-changer for every producer & songwriter!', credits: 'Ed Sheeran, Harry Styles, Post Malone, Coldplay' },

        {
            name: 'Chris Braide',
            image: Chris,
            quote: 'So easy to use and the results are impressive! I can record my vocal, choose a singer and get a completely new feel & sound.',
            credits: 'Beyonce, Sia, Nicki Minaj, Selena Gomez, Lana Del Ray',
        },
        {
            name: 'Eg White',
            image: Eg,
            quote: 'The vocals sound so strong and so real, they become part of the foundation, not the decoration.',
            credits: 'Adele, P!NK, Sam Smith, Linkin Park, Florence & The Machine',
        },
        { name: "Danny O'Donoghue", image: Danny, quote: 'This is a whole new level of creativity! The singers & rappers are amazing quality...', credits: 'The Script' },
    ]

    return (
        <section data-aos="fade-up" data-aos-delay="400">
            <div className="mx-auto max-w-6xl px-2 sm:px-6">
                <div className=" border-gray-800 py-12 md:py-20">
                    {/* Section header */}
                    <h3 className="h3 mb-12 text-center">
                        What creators are saying about Vocalist.<span className="gradient-text">ai</span>
                    </h3>
                    <div className="embla relative flex select-none">
                        <button className="px-1 md:text-xl" onClick={scrollPrev}>
                            <ArrowBackIos fontSize="inherit" />
                        </button>
                        <div className="embla__viewport" ref={emblaRef}>
                            <div className="embla__container">
                                {testimonials.map((testimonial, index) => (
                                    <div key={index} className="embla__slide">
                                        {TestimonialCard(testimonial.name, testimonial.image, testimonial.quote, testimonial.credits)}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className="px-1 md:text-xl" onClick={scrollNext}>
                            <ArrowForwardIos fontSize="inherit" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
