'use client'
// import useEmblaCarousel from 'embla-carousel-react'
// import Autoscroll from 'embla-carousel-auto-scroll'
import Image, { StaticImageData } from 'next/image'
import mbw from '@/public/images/publications/music-business-worldwide-bright-resize.png'
import musically from '@/public/images/publications/music-ally-bright-resize.png'
import musicweek from '@/public/images/publications/music-week-logo-bright-resize.png'
import thetimes from '@/public/images/publications/the-times-bright-resize.png'
import recordoftheday from '@/public/images/publications/record-of-the-day-bright-resize.png'

type CarouselItem = {
    src: StaticImageData
    alt: string
}

const carouselItems: CarouselItem[] = [
    { src: mbw, alt: 'Music Business Worldwide' },
    { src: thetimes, alt: 'The Times' },
    { src: musicweek, alt: 'Music Week' },
    { src: recordoftheday, alt: 'Record of the Day' },
    { src: musically, alt: 'Music Ally' },
]

// const doubledCarouselItems = [...carouselItems, ...carouselItems]

export default function Publications() {
    // const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoscroll({ startDelay: 0, speed: 1 })])

    return (
        <section className="relative mx-auto mt-2 overflow-hidden border-white border-opacity-10 " data-aos="fade-up" data-aos-delay="400">
            {/* <div className="mx-auto text-center h5 text-gray-200">As seen in</div> */}
            <p className="mx-auto my-4 text-center font-poppins font-extralight text-sm text-gray-300">Featured in</p>
            <div className="max-w-6x flex flex-wrap justify-center gap-y-2 gap-x-4 lg:gap-x-12">
                {carouselItems.map((item, index) => (
                    <div key={ item.alt } className="w-1/4 lg:w-auto">
                        <Image className="h-[35px] w-auto lg:h-[50px] mx-auto" height="140" src={item.src} alt={item.alt} />
                    </div>
                ))}
            </div>
        </section>
    )
}

// <div className="overflow-hidden" ref={emblaRef}>
//                 {/* mobile version */}
//                 {/* <div className="flex md:hidden">
//                     {doubledCarouselItems.map((item, index) => (
//                         <div key={index} className="min-w-0 w-fit flex-[0_0_40%] mx-auto px-6">
//                             <Image className="h-[40px] w-auto md:h-[50px]" height="140" src={item.src} alt={item.alt} />
//                         </div>
//                     ))}
//                 </div> */}
//                 {/* nonmobile version */}
//                 {/* <div className="hidden justify-center md:flex">
//                     {carouselItems.map((item, index) => (
//                         <div key={index} className="min-w-0 mx-[30px] flex-none">
//                             <Image className="h-[40px] w-auto md:h-[50px]" height="140" src={item.src} alt={item.alt} />
//                         </div>
//                     ))}
//                 </div> */}
//             </div>
