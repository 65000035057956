'use client'
import Image from 'next/image'
import SingerSide from '@/public/images/singer-side-recolor-small.png'
import BackgroundImage from '@/public/images/bg-hero-compressed.jpg'
import LogoText from '@/public/images/logo/logo_name_only.svg'
import { UserAuth } from '@/components/contexts/AuthContext'
import { Button } from '@/components/ui/elements/button'

export default function HeroMinimal() {
    const userAuth = UserAuth()

    return (
        <section className="relative overflow-hidden border-b border-white border-opacity-10">
            <div
                className="absolute inset-0 -z-10 scale-110 transform bg-xl bg-center opacity-80 blur-xl brightness-125 xl:bg-cover xl:blur-xl"
                style={{ backgroundImage: `url(${BackgroundImage.src})` }}
            ></div>
            {/* image side isnger */}
            <div className="pointer-events-none relative mx-auto h-0 max-w-6xl" aria-hidden="true">
                <div className="pointer-events-none absolute right-0 top-0 -mr-16 mt-16 hidden sm:-mr-12 sm:block md:-mr-16 lg:-mr-16" aria-hidden="true" data-aos="fade-up" data-aos-delay="100">
                    <Image src={SingerSide} alt="Vocalist.ai Artwork" className="w-[240px] md:w-[300px] lg:mt-1 lg:w-[300px]" />
                </div>
            </div>
            <div className="relative mx-auto max-w-6xl px-4 sm:px-6">
                {/* Hero content */}
                <div className="md:pt-30 relative pb-2 pt-20 md:pb-0" data-aos="fade-up">
                    {/* Logo Section */}
                    <div className="z-2 mx-auto max-w-3xl pb-8 text-center md:pb-14">
                        <Image className="mx-auto mb-6 w-[260px] md:w-[340px]" src={LogoText} width={340} alt="Vocalist.ai Logo" />
                        <h2 className="mb-8 font-poppins text-2xl font-extralight leading-snug tracking-wide md:text-2xl">Voice evolution in a click.</h2>
                        <div className="mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center">
                            <div data-aos="fade-up">
                                <Button href={userAuth?.user ? '/subscribe' : '/signup'} label="Start Free Trial" className="btn btn-vai-bright mb-2 w-full text-neutral-200 sm:mb-0 sm:w-48" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
