'use client'
import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import PlayCircle from '@mui/icons-material/PlayCircle'

const defaultPreviewUrl = '/videos/VocalistAI-Master-Br3-Sw-2pass-0001.png'
const defaultVideoUrl = '/videos/VocalistAI-Master-Br3-Sw-2pass.mp4'

interface VideoPlayerProps {
    previewUrl?: string
    videoUrl?: string
    landing?: boolean
}

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

export default function VideoPlayer({ previewUrl = defaultPreviewUrl, videoUrl = defaultVideoUrl, landing = false }: VideoPlayerProps) {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    const handlePlay = () => {
        setIsLoaded(true)
        setIsPlaying(true)
    }

    const handlePause = () => {
        setIsPlaying(false)
    }

    const handleEnded = () => {
        setIsPlaying(false)
    }

    return (
        <div className={`relative mx-auto aspect-video h-full w-full overflow-hidden ${landing && 'rounded-xl border-gray-800 md:mx-0 md:max-w-[300px] lg:max-w-[470px]'}`}>
            {!isLoaded && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <Image src={previewUrl} alt="Video preview" fill className="object-cover" sizes="(max-width: 768px) 100vw, 50vw"/>
                    <div className="absolute inset-0 bg-black opacity-40"></div>
                    <button onClick={handlePlay} className="absolute z-10 transform transition-transform duration-300 hover:scale-110" aria-label="Play video">
                        <PlayCircle color="inherit" fontSize="inherit" className="text-[70px] text-white" />
                    </button>
                </div>
            )}
            {isLoaded && <ReactPlayer playing={isPlaying} controls width="100%" height="100%" url={videoUrl} onPlay={handlePlay} onPause={handlePause} onEnded={handleEnded} />}
        </div>
    )
}
