'use client'
import React, { useEffect, useState } from 'react'
import { Artist } from '@/components/contexts/ArtistContext'
import ArtistList from '@/components/artist-list'
import { Button } from '@mui/material'

interface ArtistPreviewBrowserProps {
    artists: Artist[] | null
}

export default function ArtistPreviewBrowser({ artists }: ArtistPreviewBrowserProps) {
    const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null)
    const [isBrowsing, setIsBrowsing] = useState(false)
    const [demoAudio, setDemoAudio] = useState<HTMLAudioElement | null>(null)

    useEffect(() => {
        // This code runs only on the client after the component has mounted
        setDemoAudio(new Audio())
    }, [])

    const handleSelectArtist = () => {
        console.log('Closing artist browser')
        demoAudio?.pause()
        setIsBrowsing(false)
    }

    const openButton = () => {
        return (
            <Button className="btn-sm w-full md:w-48  btn-vai-invert px-3 normal-case text-neutral-300 hover:text-neutral-300" onClick={() => setIsBrowsing(true)}>
                View our Artists
            </Button>
        )
    }

    if (!isBrowsing || !demoAudio) {
        return openButton()
    }

    return (
        <div>
            {openButton()}
            {isBrowsing && demoAudio && (
                <div onClick={() => setIsBrowsing(false)} className="fixed inset-0 z-[1000] flex items-center justify-center overflow-y-hidden bg-black/50 transition-opacity">
                    <div
                        onClick={(e) => e.stopPropagation()}
                        id="artist_preview_browser"
                        data-aos="fade-up"
                        data-aos-delay="100"
                        className="bg-gradient relative flex h-svh w-full flex-col border-white border-opacity-10
        px-4 py-4 font-normal md:py-6 lg:m-auto lg:h-auto lg:max-w-[800px] rounded-lg lg:border lg:p-6 xl:max-w-[1200px]"
                    >
                        <div className="mx-auto mb-2 text-center">
                            <h4 className="h4 sm:h5">Artist Browser</h4>
                            <p className="my-2 text-sm text-gray-300">Preview all our vocalists below, along with demos of their transformation outputs when used in full-mixed tracks.</p>
                        </div>
                        <div className="app-border my-2 hidden h-0 flex-row items-center border-b border-opacity-90 sm:flex"></div>
                        <div className="relative mt-0 flex h-full overflow-hidden sm:mt-2">
                            <ArtistList selectedArtist={selectedArtist} setSelectedArtist={setSelectedArtist} demoAudio={demoAudio} artists={artists} />
                        </div>
                        <div className="app-border my-2 mt-4 hidden h-0 flex-row items-center border-b border-opacity-90 sm:flex"></div>
                        <div className="relative mt-5 flex">
                            <div className="flex grow flex-col">
                                <button id="select_artist" type="submit" className={`btn btn-vai h-10 w-full`} onClick={handleSelectArtist}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
