'use client'
import React, { useState } from 'react'
import WaveSurferComponent from '@/components/ui/wavesurfer-component'

interface AudioComparisonProps {
    inputAudioUrl?: string
    transformedAudioUrl?: string
    fullAudioUrl?: string
}

const AudioComparison: React.FC<AudioComparisonProps> = ({
    inputAudioUrl = '/audio/teardrops/Input.mp3',
    transformedAudioUrl = '/audio/teardrops/Transformed.mp3',
    fullAudioUrl = '/audio/teardrops/Full.mp3',
}) => {
    const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null)

    const handlePlayPause = (audioUrl: string) => {
        setCurrentlyPlaying((prevPlaying) => (prevPlaying === audioUrl ? null : audioUrl))
    }

    return (
        <div className="relative grid w-full gap-6">
            <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-end">
                <div className="relative w-48 pb-1 text-center md:mr-4 lg:w-auto lg:text-right">
                    <div className="h6 text-sm lg:text-base">Input Vocal</div>
                    <div className="text-xs text-vai-prose lg:text-sm">Dry (No FX)</div>
                </div>
                <div className="w-[20rem]">
                    <WaveSurferComponent audioUrl={inputAudioUrl} style="landing" download={false} currentlyPlaying={currentlyPlaying} onPlayPause={() => handlePlayPause(inputAudioUrl)} />
                </div>
            </div>
            <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-end">
                <div className="relative w-48 pb-1 text-center md:mr-4 lg:w-auto lg:text-right">
                    <div className="h6 text-sm lg:text-base">Output Vocal</div>
                    <div className="text-xs text-vai-prose lg:text-sm">
                        Transformed
                    </div>
                </div>
                <div className="w-[20rem]">
                    <WaveSurferComponent
                        audioUrl={transformedAudioUrl}
                        style="landing"
                        borderStyle="border-2"
                        borderColorClass="border-vai-button-f"
                        download={false}
                        currentlyPlaying={currentlyPlaying}
                        onPlayPause={() => handlePlayPause(transformedAudioUrl)}
                    />
                </div>
            </div>
            <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-end">
                <div className="relative w-48 pb-1 text-center md:mr-4 lg:w-auto lg:text-right">
                    <div className="h6 text-sm lg:text-base">Full Composition</div>
                    <div className="text-xs text-vai-prose lg:text-sm">Full FX and Processing</div>
                </div>
                <div className="w-[20rem]">
                    <WaveSurferComponent
                        audioUrl={fullAudioUrl}
                        style="landing"
                        borderStyle="border-2"
                        borderColorClass="border-vai-text-gradient-f"
                        download={false}
                        currentlyPlaying={currentlyPlaying}
                        onPlayPause={() => handlePlayPause(fullAudioUrl)}
                    />
                </div>
            </div>
        </div>
    )
}

export default AudioComparison