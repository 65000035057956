import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/artist-preview-browser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/audio-comparison-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/publications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/section-break.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/landing/videoPlayer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/dot-wave2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/photos/carousel.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/photos/studio-dark.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/third-party/aiformusic/logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/third-party/aiformusic/text.png");
