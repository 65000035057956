'use client'
import BackgroundImage from '@/public/images/bg-hero-compressed.jpg'
import Link from 'next/link'
import { UserAuth } from '@/components/contexts/AuthContext'

export default function SectionBreak() {
    const userAuth = UserAuth()
    
    return (
        <section className="relative overflow-hidden border-y border-white border-opacity-10 ">
            <div
                className="absolute inset-0 -z-10 scale-110 transform bg-xl bg-center opacity-70 blur-lg brightness-125 xl:bg-cover xl:blur-xl"
                style={{ backgroundImage: `url(${BackgroundImage.src})` }}
            ></div>
            <div className="relative mx-4 my-16 md:mx-12">
                <div className="mx-auto max-w-6xl">
                    <div className="text-md mx-auto mb-6 max-w-3xl text-center md:text-md" data-aos="fade-up" data-aos-delay="100">
                        <p className="h3 mb-4">
                            Highest Quality, Ultra Fast, <span className="gradient-text">Royalty-Free</span>
                        </p>
                        <p className="relative text-vai-prose">
                            Even on our starter tier, you’ll have access to our highest quality algorithm, ultra-fast GPU processing, and of course, our voices are royalty-free. Always.
                        </p>
                    </div>
                    <div className="mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center text-center">
                        <div data-aos="fade-up" data-aos-delay="400">
                            <Link className="btn btn-vai-bright mb-2 w-full text-neutral-200 sm:mb-0 sm:w-48" href={userAuth?.user ? '/subscribe' : '/signup'}>
                                Start Free Trial
                            </Link>
                        </div>
                        {/* <div data-aos="fade-up" data-aos-delay="600">
                                <Link className="btn btn-vai-white w-full text-white sm:ml-4 sm:w-auto" href="/subscribe">
                                    View Pricing
                                </Link>
                            </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
